<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <v-btn
            class="ma-2"
            dark
            color="primary"
            @click="$router.push('/app/blog/create')"
          >
            <v-icon>mdi-plus</v-icon>
            Add new article
          </v-btn>
        </v-card-title>
        <v-card-title>
          <template v-if="page">
            {{ page.title }}
          </template>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :search="search"
          :headers="headers"
          :footer-props="{'items-per-page-options':[-1, 15, 30, 50, 100]}"
          :items="headers.length ? getBlogs : []"
          item-key="id"
          class="elevation-1 table-one"
          multi-sort
        >
          <template v-slot:item.updated_at="{ item }">
            <span>{{ item.updated_at | datetime }}</span>
          </template>
          <template v-slot:item.enabled="{ item }">
            <v-icon v-if="item.enabled">mdi-check</v-icon>
          </template>
          <template v-slot:item.action="{ item }">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="$router.push(`/app/blog/${item.id}/edit`)"
                  >
                    <v-icon>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="danger"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="confirmDelete(item.id)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </base-card>
      <v-dialog v-model="deleteDialog" max-width="290">
        <v-card>
          <v-card-title class="text-h6">Delete article</v-card-title>
          <v-card-text>
            The article will be deleted permanently. Are you sure?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="deleteDialog = false">
              Cancel
            </v-btn>
            <v-btn
              color="danger"
              text
              :loading="getBlogsLoading"
              @click="deleteBlog"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import store from "@/store";
import _ from "lodash";
import moment from "moment";

export default {
  metaInfo: {
    title: "Blog"
  },
  data() {
    return {
      model: "blog",
      page: null,
      search: "",
      selectedId: null,
      headers: [
        { text: "Title", value: "title" },
        { text: "Short title", value: "title_short" },
        { text: "Publish date", value: "date" },
        { text: "Last updated", value: "updated_at" },
        { text: "Enabled", value: "enabled" },
        { text: "Action", value: "action", sortable: false }
      ],
      deleteDialog: false
    };
  },
  mounted() {
    this.page = store.getters.getPage({ model: this.model });
  },
  methods: {
    ...mapActions(["removeBlog"]),
    confirmDelete(id) {
      this.selectedId = id;
      this.deleteDialog = true;
    },
    async deleteBlog() {
      await this.removeBlog(this.selectedId);
      this.deleteDialog = false;
    }
  },
  computed: {
    ...mapGetters(["getBlogs", "getBlogsLoading"])
  },
  filters: {
    datetime(value) {
      return moment(value).format("YYYY-MM-DD HH:mm:ss");
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }
    }
  }
}
</style>
